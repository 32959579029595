import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Div, P } from './styled-components'

const Certification = ({ background }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "aabc.png" }) {
        childImageSharp {
          fluid(maxWidth: 221) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Div
      width="100%"
      padding="50px 10px"
      display="flex"
      flexDirection="column"
      align_items="center"
      justify_content="center"
      background_color={background}
    >
      <Img
        fluid={data.image.childImageSharp.fluid}
        style={{
          margin: "0 0 20px 0",
          width: "221px",
          height: "221px"
        }}
      />
      <P
        margin="0 0 5px"
        fontSize="32px"
        fontWeight="bold"
        color="#002f65"
        textAlign="center"
      >
        CERTIFICATION BY <br/> ASSOCIATED AIR BALANCE COUNCIL
      </P>
    </Div>
  )
}

export default Certification